.main-header{
    height: 70px;
    width: 100%;
    top: 0;
    z-index: 10;
}
.main-header.is-scrolled{
    background-color: #0E0E0E;
    position: fixed;
    box-shadow: 0px 3px 25px rgba(56, 182, 255, 0.13);
}
.logo{
    color: #fff;
    font-weight: 800;
    letter-spacing: 2px;
    /* font-size: 38px; */
    font-size: 1rem;
}
.main-header .menu ul{
    list-style: none;
    margin: 0;
}
.main-header .menu ul li{
    float: left;
    margin: 0 3px;
}
.main-header .menu ul li a{
    padding: 5px 10px;
    color: #fff;
    /* font-size: 17px; */
    font-size: 1rem;
    text-transform: uppercase;
    /* letter-spacing: 1px; */
    font-weight: 500;
    transition: color .2s ease;
    cursor: pointer;
}   
.main-header .menu ul li:hover a{
    /* background: #fff; */
    color: rgb(0, 129, 254);
}   


/* ==================== Mobile menu ==================== */
.mobile-menu-btn{
    background: #fff;
    padding: 9px 10px;
    border-radius: 7px;
    color: #000;
}
.mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    border-radius: 0px;
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
    background-color: #0E0E0E;
    box-shadow: 0px 3px 25px rgba(56, 182, 255, 0.13);
}


.mobile-menu .menu-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
    padding: 0px 0px;
    z-index: 5;
    box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}
.mobile-menu-visible .mobile-menu .close-btn {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}
.mobile-menu .close-btn {
    position: absolute;
    right: 15px;
    top: 28px;
    line-height: 30px;
    width: 35px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    -webkit-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    transition: all 0.9s ease;
}
.mobile-menu .nav-logo {
    position: relative;
    padding: 30px 25px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-menu .navigation {
    position: relative;
    display: block;
    width: 100%;
    float: none;
}
.mobile-menu .navigation li:first-child {
    border-top: 1px solid rgba(56, 182, 255, 0.6);
}
.mobile-menu .navigation li {
    position: relative;
    display: block;
    border-bottom: 1px solid rgba(56, 182, 255, 0.6);
}
.mobile-menu .navigation li > a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 60px 10px 25px;
    padding: 0.5em;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: none;
    letter-spacing: 0.08em;
    /* border-radius: 4px; */
}
.mobile-menu .navigation li > a:hover{
    background-color: #fff;
    color: rgb(0, 129, 254);
}
.mobile-menu.mobile-menu-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}
.mobile-menu.mobile-menu-visible .menu-box {
    opacity: 1;
    visibility: visible;
}
.nav-btn{
    background: linear-gradient(0deg, rgba(253,208,64,1) 0%, rgba(246,141,23,1) 100%);
    padding: 0.8em 1.6em;
    color: white;
    border: none;
    border-radius: 5px;
}
.btn-orange, .btn-orange:hover{
    background: linear-gradient(0deg, rgba(253,208,64,1) 0%, rgba(246,141,23,1) 100%);
    color: #fff;
}